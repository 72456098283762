import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="fulfillment--populate-rates"
export default class extends Controller {
  static targets = ["selectRate", "ratediv"];
  static values = { rates: Array, orderId: String, selectedRate: Object };

  createOption(option) {
    const $option = $("<option></option>").val(option.id).text(option.text);

    $.each(option.dataAttributes, function (key, value) {
      $option.attr(`data-${key}`, value); // Prefix with `data-`
    });

    return $option;
  }

  connect() {
    const selectTarget = this.ratedivTarget.querySelector("select");

    $(selectTarget).select2();

    let selectedOption = null;

    this.ratesValue.forEach((option) => {
      $(selectTarget).append(this.createOption(option));

      if (
        this.compareAllButRateId(JSON.parse(option.id), this.selectedRateValue)
      ) {
        selectedOption = option.id;
      }
    });
    $(selectTarget).select2();

    if (this.selectedRateValue) {
      $(selectTarget).val(selectedOption).trigger("change");
    }

    $(selectTarget).on("select2:select", () => {
      var form = document.getElementById(`form_${this.orderIdValue}`);

      if (form) {
        form.action = form.action.replace("/print_label", "/set_rates");
        form.requestSubmit();
        form.action = form.action.replace("/set_rates", "/print_label");
      }
    });
  }

  compareAllButRateId(obj1, obj2) {
    for (let key in obj1) {
      if (key !== "rate_id" && obj1[key] !== obj2[key]) {
        return false;
      }
    }
    return true;
  }
}
