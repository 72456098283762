import { Application } from "@hotwired/stimulus";
import Autosave from "stimulus-rails-autosave";

const application = Application.start();
application.register("autosave", Autosave);

import { Popover } from "tailwindcss-stimulus-components";
application.register("popover", Popover);

import AdminOrder__OrderItemController from "./admin_order/order_item_controller";
application.register(
  "admin-order--order-item",
  AdminOrder__OrderItemController
);

import Fulfillment__FetchDataController from "./fulfillment/fetch_data_controller";
application.register(
  "fulfillment--fetch-data",
  Fulfillment__FetchDataController
);

import Fulfillment__MarkAsShippedController from "./fulfillment/mark_as_shipped_controller";
application.register(
  "fulfillment--mark-as-shipped",
  Fulfillment__MarkAsShippedController
);

import Fulfillment__GetRatesController from "./fulfillment/get_rates_controller";
application.register("fulfillment--get-rates", Fulfillment__GetRatesController);

import Fulfillment__PopulateRatesController from "./fulfillment/populate_rates_controller";
application.register(
  "fulfillment--populate-rates",
  Fulfillment__PopulateRatesController
);

import Fulfillment__PrintLabelController from "./fulfillment/print_label_controller";
application.register(
  "fulfillment--print-label",
  Fulfillment__PrintLabelController
);

import ProductVariant__MergeController from "./product_variant/merge_controller";
application.register("product-variant--merge", ProductVariant__MergeController);

import Order__ValidateAddressController from "./order/validate_address_controller";
application.register(
  "order--validate-address",
  Order__ValidateAddressController
);

import Order__SetShippingController from "./order/set_shipping_controller";
application.register("order--set-shipping", Order__SetShippingController);

import Product__TagsController from "./product/tags_controller";
application.register("product--tags", Product__TagsController);

import ShippingRateSelectorController from "./shipping_rate_selector_controller";
application.register("shipping-rate-selector", ShippingRateSelectorController);
