import { Controller } from "@hotwired/stimulus";
import { get, post } from "@rails/request.js";

// Connects to data-controller="fulfillment--print-label"
export default class extends Controller {
  static targets = ["orderform", "generateLabel"];

  connect() {
    this.generateLabelTarget.addEventListener("click", () => {
      this.submitForms();
    });
  }

  submitForms() {
    this.orderformTargets.forEach((form) => {
      post(form.action, {
        responseKind: "turbo-stream",
      }).then((response) => {
        if (response.ok) {
          console.log("Success:", response.statusText);
        } else {
          console.error("Failed:", response.statusText);
        }
      });
    });
  }
}
