import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="home"
export default class extends Controller {
  static targets = ["cheapest", "upsground", "uspspriority", "twoday"];

  connect() {
    console.log(this.cheapestTarget);
    this.cheapestTarget.addEventListener("click", () =>
      this.handleShippingClick("data-cheapest")
    );
    this.upsgroundTarget.addEventListener("click", () =>
      this.handleShippingClick("data-ups_ground")
    );
    this.uspspriorityTarget.addEventListener("click", () =>
      this.handleShippingClick("data-usps_priority")
    );
    this.twodayTarget.addEventListener("click", () =>
      this.handleShippingClick("data-two_day_shipping")
    );
  }

  handleShippingClick = (dataAttribute) => {
    const selectElements = document.querySelectorAll(
      ".shipping-rate-estimates"
    );

    selectElements.forEach(async (selectElement) => {
      const shippingOption = selectElement.querySelector(
        `option[${dataAttribute}="true"]`
      );

      if (shippingOption) {
        jQuery(selectElement).val(shippingOption.value);
      } else {
        console.log(`No option found for ${dataAttribute}.`);
      }
    });

    selectElements.forEach(async (selectElement) => {
      await this.sleep(1000);
      await jQuery(selectElement).trigger("select2:select");
    });
  };

  sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };
}
